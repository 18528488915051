import { Icon } from '@bbnpm/bb-ui-framework';
import './error.min.css';

const Error = () => {
    const SITE_ADDRESS = process.env.PUBLIC_URL + '/';
    const htmlTitle = sanitizeHTML(window.SETTINGS.bannerErrorTitle);
    const htmlDetails = sanitizeHTML(window.SETTINGS.bannerErrorDetails);

    function sanitizeHTML(backendHTML) {
        // Using replace, and not replaceAll, to support older browsers
        return backendHTML
            .replace(new RegExp("&amp;", 'g'), "&")
            .replace(new RegExp("&lt;", 'g'), "<")
            .replace(new RegExp("&gt;", 'g'), ">")
            .replace(new RegExp("&#39;", 'g'), "'")
            .replace(new RegExp("&#34;", 'g'), "'");
    }

    return (
        <div className="errorContainer">
            <div className="error">
                <div className='content'>
                    <div className="message">
                        <Icon name="exclamation-circle-alt" />
                        <div>
                            <h1 className='page-title'>{htmlTitle}</h1>
                            <code>{htmlDetails}</code>
                        </div>
                    </div>
                    <hr />
                    <div className="resourceContainer">
                        <div></div>
                        <div className="resourceContent">
                            <p>Here are some helpful links:</p>
                            <a href={SITE_ADDRESS + "help"} rel="noreferrer">BBA Help</a>
                            <a href={SITE_ADDRESS} rel="noreferrer">Return Home</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Error;