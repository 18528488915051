import {
  BBUIApp,
  Banner,
  Button,
  Content,
  Footer,
  Icon,
  Main,
  Modal,
  TitleBar,
  useNotification
} from '@bbnpm/bb-ui-framework';
import { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  useLocation
} from "react-router-dom";
import './App.min.css';
import ErrorBoundary from './ErrorBoundary';
import Downloads from './downloads';
import Error from './error';
import Help from './help';
import Landing from './landing';
import Launch from './launch';

const App = () => {
  const SITE_ADDRESS = process.env.PUBLIC_URL + '/';
  const APPWIDTH = 1280,
    location = useLocation();
  const htmlTitle = sanitizeHTML(window.SETTINGS.bannerErrorTitle);
  const htmlDetails = sanitizeHTML(window.SETTINGS.bannerErrorDetails);
  const [citrixPopup, setCitrixPopup] = useState(true);
  const popUpMessage = (window.SETTINGS.popUp)
  const notif = useNotification();
  const [hasServerError, setHasServerError] = useState(htmlTitle.length > 0 || htmlDetails.length > 0);

  function sanitizeHTML(backendHTML) {
    // Using replace, and not replaceAll, to support older browsers
    return backendHTML
      .replace(new RegExp("&amp;", 'g'), "&")
      .replace(new RegExp("&lt;", 'g'), "<")
      .replace(new RegExp("&gt;", 'g'), ">")
      .replace(new RegExp("&#39;", 'g'), "'")
      .replace(new RegExp("&#34;", 'g'), "'");
  }

  useEffect(() => {
    if (!hasServerError) {
      notif.clearAll();
      return;
    }

    const addErrorNotification = new Promise((resolve, reject) => {
      // No banner notification if we are on the error page
      if (htmlTitle && window.location.pathname !== "/error") {
        if (htmlDetails) {
          notif.addError({
            message: htmlTitle,
            details:
              <div className="bbui-notification-details-content">
                {htmlDetails && <span dangerouslySetInnerHTML={{ __html: htmlDetails }}></span>}
              </div>
          });
        } else {
          notif.addError({
            message: htmlTitle
          });
        }
        resolve();
      }
      reject();
    });

    // default to open error notification
    addErrorNotification
      .then(() => {
        let message = document.querySelector("#bbui-notification-container .bbui-notification-message");
        if (message) message.ariaExpanded = true;

        let dropdown = document.querySelector("#bbui-notification-container #bbui-notification-error-dropdown");
        if (dropdown) {
          dropdown.ariaHidden = false;
          setTimeout(() => dropdown.style.maxHeight = "none", 100);
        }

        return message;
      })
      .then(message => {
        if (message) message.click(); // toggle default closed state
      })
      .catch(() => { });
  }, [htmlDetails, htmlTitle, hasServerError, notif]);

  return (
    <BBUIApp activeTheme="dark">

      {window.SETTINGS.bannerMessage.length > 0 &&
        window.SETTINGS.bannerKind.length > 0 &&
        window.SETTINGS.bannerLinkText.length == 0 &&
        window.SETTINGS.bannerLinkHref.length == 0 &&
        <Banner kind={window.SETTINGS.bannerKind} message={window.SETTINGS.bannerMessage} />}

      {window.SETTINGS.bannerMessage.length > 0 &&
        window.SETTINGS.bannerKind.length > 0 &&
        window.SETTINGS.bannerLinkText.length > 0 &&
        window.SETTINGS.bannerLinkHref.length > 0 &&
        <Banner kind={window.SETTINGS.bannerKind} message={window.SETTINGS.bannerMessage}
          link={<a href={window.SETTINGS.bannerLinkHref}>{window.SETTINGS.bannerLinkText}</a>} />}

      {(location.pathname === '/') || (location.pathname === '/client') ? <div className='above-title'>
        <ul>
          <li><a href="https://www.bloomberg.com/company/" target="_blank" rel="noreferrer" tabIndex="-1">Bloomberg the Company & its Products <Icon name="popout" /></a></li>
          {(location.pathname === '/') ? <li><a href="https://www.bloomberg.com/professional/contact-menu/?utm_source=bbgmenu-demo&bbgsum=DG-WS-CORE-bbgmenu-demo" target="_blank" rel="noreferrer" tabIndex="-1">Bloomberg Terminal Demo Request <Icon name="popout" /></a></li> : ''}
        </ul>
      </div> : ''}

      <Modal
        id="citrixModal"
        isOpen={((popUpMessage !== "" && popUpMessage !== null) && citrixPopup)}
        portalContainerId="root"
        primaryActions={
          <>
            <Button kind="secondary" onClick={() => setCitrixPopup(false)}>
              Ok
            </Button>
          </>
        }
      >
        <h1>Welcome to Bloomberg Anywhere</h1>
        <p>{popUpMessage}</p>
      </Modal>

      <TitleBar
        productName="ANYWHERE"
        brandRenderer={({ children, ...rest }) => (
          <a href={SITE_ADDRESS} {...rest}>
            {children}
          </a>
        )}
        envIndicator={window.SETTINGS.env.length > 0 && <span style={{ color: 'cyan' }}>{window.SETTINGS.env}</span>}
        width={APPWIDTH + 'px'}
      >
        <TitleBar.Links>
          {location.pathname !== SITE_ADDRESS + 'help' &&
            <TitleBar.IconLink
              iconName='question-circle'
              text="Help"
              onClick={() => window.open(SITE_ADDRESS + 'help', '_blank').focus()}
            />
          }

          {location.pathname === SITE_ADDRESS + 'launch' &&
            <TitleBar.IconLink
              iconName="user"
              text="User"
              menuItems={[
                {
                  label: 'Logout',
                  renderer: 'a',
                  to: SITE_ADDRESS + 'logout',
                  href: SITE_ADDRESS + 'logout'
                }
              ]}
            />
          }
        </TitleBar.Links>
      </TitleBar>

      <Main>
        <Content className="main-content">
          <Switch>
            <Route exact path={SITE_ADDRESS + ''}>
              <Landing />
            </Route>
            <Route path={SITE_ADDRESS + 'error'}>
              <Error />
            </Route>
            <Route path={SITE_ADDRESS + 'help'}>
              <Help />
            </Route>
            <Route path={SITE_ADDRESS + 'downloads'}>
              <Downloads />
            </Route>
            <ErrorBoundary fallback={<Error />}>
              <Route path={SITE_ADDRESS + 'launch'}>
                <Launch setHasServerError={setHasServerError} />
              </Route>
            </ErrorBoundary>
          </Switch>
        </Content>
      </Main>

      <Footer copyright={new Date().getFullYear()} width={APPWIDTH + 'px'}>
        <Footer.Links>
          <Footer.Link>
            <a href="https://www.bloomberg.com/feedback/">Contact Us</a>
          </Footer.Link>
          <Footer.Link>
            <a href="https://www.bloomberg.com/notices/tos/">Terms and Conditions of Use</a>
          </Footer.Link>
          <Footer.Link>
            <a href="https://www.bloomberg.com/notices/trademarks/">Trademarks</a>
          </Footer.Link>
          <Footer.Link>
            <a href="https://www.bloomberg.com/notices/privacy/">Privacy Policy</a>
          </Footer.Link>
        </Footer.Links>
      </Footer>
    </BBUIApp>
  );
}

export default App;
